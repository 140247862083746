//import './_common.js'
import styles from '../css/app.scss';
import 'vue-resize/dist/vue-resize.css';

import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';

import axios from 'axios';
import { ResizeObserver } from 'vue-resize';

Vue.use(new VueSocketIO({
    debug: true,
    connection: process.env.NODEJS_CONNECTION_URL,
    options: { } //Optional options
}));

const vm = new Vue({
  el: "#homeApp",
  delimiters: ['[[', ']]'],

  sockets: {
    connect: function () {
      //console.log('socket connected');
    },
    shownFlag: function (slug) {
      this.getNowShowing(slug);
    }
  },

  components: {
    'resize-observer': ResizeObserver,
  },
  data: {
    loading: false,
    intervalId: 0,
    tickerX: 0,
    tickerMinX: 0,
    tickerTapeWidth: 0,
  },
  methods: {

    handleViewResize(){
      this.setupTicker();
    },

    doTick(){
      this.tickerX -= 30;
      var addedStatic = false;
      var $innerTickerTape = jQuery('.now-showing-flag-wrapper .ticker-tape .inner');
      if (this.tickerX < this.tickerMinX) {
        this.tickerX += this.tickerTapeWidth + 30;
        addedStatic = true;
        // .offsetHeight triggers reflow of DOM
        $innerTickerTape.removeClass('anim').get(0).offsetHeight;
      }
      $innerTickerTape.css({ left: this.tickerX+"px" });
      if (addedStatic) {
        $innerTickerTape.get(0).offsetHeight;
        $innerTickerTape.addClass('anim');
        this.doTick();
      }
    },

    setupTicker(){
      var viewportWidth = jQuery('.now-showing-flag-wrapper').width();
      this.tickerTapeWidth = jQuery('.now-showing-flag-wrapper .ticker-tape .inner .original').outerWidth();
      clearInterval(this.intervalId);
      //console.log(" ticker --- viewportWidth: "+viewportWidth+" / this.tickerTapeWidth: "+this.tickerTapeWidth);
      if (this.tickerTapeWidth > viewportWidth) {
        if (jQuery('.now-showing-flag-wrapper .ticker-tape .inner .copy').length == 0) {
          var origHtml = jQuery('.now-showing-flag-wrapper .ticker-tape .inner .original').html();
          jQuery('.now-showing-flag-wrapper .ticker-tape .inner').append('<div class="copy">'+origHtml+'</div>');
        }
        this.tickerX = 0;
        this.tickerMinX = 0 - this.tickerTapeWidth;
        jQuery('.now-showing-flag-wrapper .ticker-tape .inner').css({ left: this.tickerX+"px" }).addClass('anim');

        this.intervalId = setInterval(function(){
          this.doTick();
        }.bind(this), 1000);
      } else {
        this.tickerX = 0;
        this.tickerMinX = 0;
        if (jQuery('.now-showing-flag-wrapper .ticker-tape .inner .copy').length > 0) {
          jQuery('.now-showing-flag-wrapper .ticker-tape .inner').css({ left: '' });
          jQuery('.now-showing-flag-wrapper .ticker-tape .inner .copy').remove();
        }
      }
    },

    getNowShowing(flagSlug) {
      jQuery('.now-showing-flag-wrapper').addClass('loading');
      this.loading = true;
      axios.get("/home-flag-show/"+flagSlug)
        .then((response)  =>  {
          jQuery('.now-showing-flag-wrapper').removeClass('loading');
          this.loading = false;
          var html = response.data;
          jQuery('.now-showing-flag-wrapper').html(html);
          this.setupTicker();
          window.winResize();
        }, (error)  =>  {
          jQuery('.now-showing-flag-wrapper').removeClass('loading');
          this.loading = false;
        })
    }

  },
  mounted() {

    this.setupTicker();
    // this.$socket.emit('emit_method', { 'test': 'hello' });

    // this.sockets.subscribe('EVENT_NAME', (data) => {
    //     this.msg = data.message;
    // });

    //this.$socket.sendObj({awesome: 'data'});

    // this.sockets.onmessage = (data) => {
    //   console.log(data);
    // }

  },
});
